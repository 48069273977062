var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{staticClass:"mb-4 profile-history-filter-select-combobox",attrs:{"label":_vm.label,"items":_vm.itemsList,"loading":_vm.loading,"outlined":"","multiple":"","chips":"","deletable-chips":"","hide-details":"","small-chips":"","item-text":_vm.itemText,"return-object":"","menu-props":{
    maxHeight: '344px',
    rounded: 'lg',
    contentClass: 'profile-history-filter-select-combobox-menu'
  }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
  var item = ref.item;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.handleItemSelect(item)}}},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])]}},{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('v-list-item',{class:{
        'profile-history-filter-select-combobox-list-item': true,
        'profile-history-filter-select-combobox-selected-list-item': _vm.isSelected(item)
      },on:{"click":function($event){return _vm.handleItemSelect(item)}}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":_vm.isSelected(item)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"profile-history-filter-select-combobox-list-item-text"},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])],1)],1)]}}]),model:{value:(_vm.localState),callback:function ($$v) {_vm.localState=$$v},expression:"localState"}})}
var staticRenderFns = []

export { render, staticRenderFns }