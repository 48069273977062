<template>
  <v-combobox

    v-model="localState"
    :label="label"
    :items="itemsList"
    :loading="loading"
    outlined
    multiple
    chips
    deletable-chips
    hide-details
    small-chips
    class="mb-4 profile-history-filter-select-combobox"
    :item-text="itemText"
    return-object
    :menu-props="{
      maxHeight: '344px',
      rounded: 'lg',
      contentClass: 'profile-history-filter-select-combobox-menu'
    }"
  >
    <template #selection="{ item }">
      <v-chip
        close
        @click:close="handleItemSelect(item)"
      >
        {{ item[itemText] }}
      </v-chip>
    </template>

    <template #item="{ item }">
      <v-list-item
        :class="{
          'profile-history-filter-select-combobox-list-item': true,
          'profile-history-filter-select-combobox-selected-list-item': isSelected(item)
        }"
        @click="handleItemSelect(item)"
      >
        <v-list-item-action>
          <v-checkbox
            :input-value="isSelected(item)"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="profile-history-filter-select-combobox-list-item-text">
            {{ item[itemText] }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'ProfileHistoryFilter',
  // eslint-disable-next-line vue/require-prop-types
  props: ['value', 'itemsList', 'label', 'itemText', 'loading'],

  computed: {
    localState: {
      get() {
        return this.value;
      },
      set(localState) {
        this.$emit('input', localState);
      },
    },
  },

  methods: {
    isSelected(item) {
      return this.localState.find((i) => i[this.itemText] === item[this.itemText]);
    },

    handleItemSelect(item) {
      const isSelected = this.localState.find((i) => i[this.itemText] === item[this.itemText]);

      const items = isSelected
        ? this.localState.filter((i) => i[this.itemText] !== item[this.itemText])
        : [...this.localState, item];
      this.localState = items;
    },

  },
};
</script>
