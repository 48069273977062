<template>
  <div>
    <topbar />
    <main-table />
  </div>
</template>

<script>
import MainTable from '@/components/runningTime/RunningTimeMainTable.vue';
import Topbar from '../components/runningTime/RunningTimeTopbar.vue';

export default {
  name: 'RunningTime',
  components: { MainTable, Topbar },
  // created() {
  //   this.$store.dispatch('currencies/loadCurrencies');
  // },

  // beforeDestroy() {
  //   this.$store.dispatch('currencies/clearSelected');
  // },
};
</script>

<style scoped>

</style>
